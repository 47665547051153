

import { Vue, Component } from 'vue-property-decorator'
import { WorkerDetail } from '@/types/onlineStatistics'
import { Page } from '@/types/common'

@Component
export default class OnlineStatisticsDetail extends Vue {
  page = 1
  size = 10
  total = 0
  loading = false
  tableData: WorkerDetail[] = []

  searchForm = {
    workerName: '',
    dutyStatus: ''
  }

  created () {
    this.loadData()
  }

  get dutyId () {
    return this.$route.query.dutyId
  }

  loadData () {
    this.loading = true
    this.$axios.get<Page<WorkerDetail>>(this.$apis.recruitUse.selectYhWorkerDutyCount, {
      page: this.page,
      size: this.size,
      dutyId: this.dutyId,
      ...this.searchForm
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }
}
